<script>
  export let label
  export let value
</script>

<style>
  div {
    display: flex;
    font-size: 12px;
  }
  
  span {
    padding-left: 2px;
    font-weight: bold;
  }
</style>

<div>
  <label>{label}</label>
  <span>{value}</span>
</div>