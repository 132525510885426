<script>
  import Token from './components/token.svelte'

export let you
</script>

<style>
  div {
    padding: 30px 20px;
  }

  label {
    font-size: 12px;
    font-weight: bold;
    margin-left: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
</style>

<div>
  <label>Bot Tokens:</label>
  <ul>
    {#each you.bots as bot (bot.id)}
    <li><Token value={bot.hash}/></li>
    {/each}
  </ul>
</div>
