<script>
  import _ from 'lodash'

  import GameMap from './game-map.svelte'
  // import GameStats from './game-stats.svelte'
  import Sidebar from './sidebar/index.svelte'

  export let ws

  let gameMap, you, others

  ws.onmessage = msg => {
    const [type, ...raw] = msg.data.split('|')
    const data = JSON.parse(raw.join('|'))
    handlers[type](data)
  }

  const handlers = {}
  handlers['join-game-success'] = data => {
    you = data.you
    others = data.others
    gameMap = data.map
  }
  handlers['player-joined'] = player => {
    others = [...others, player]
  }
  handlers['player-left'] = id => {
    const i = _.findIndex(others, { id })
    others.splice(i, 1)
    others = others
  }
  handlers['game-tick'] = data => {
    you = data.you
    others = data.others
  }

  const getBots = player =>
    player.bots.map(bot => ({ ...bot, color: player.color }))
  const getBase = ({ id, color, base }) => ({ id, ...base, color })

  $: bots = gameMap ? [..._.flatMap(others, getBots), ...getBots(you)] : null
  $: bases = gameMap ? [...others.map(getBase), getBase(you)] : null

  ws.send('join-game')
</script>

<style>
  .wrapper {
    display: flex;
  }
</style>

{#if gameMap}
  <div class="wrapper">
    <GameMap {gameMap} {bots} {bases} />
    <Sidebar {you} {others} />
    <!-- <GameStats {you} {others} /> -->
  </div>
{/if}
