<script>
  export let value

  const handleClick = e => e.target.select()
</script>

<style>
  input {
    border: 1px solid #ccc;
    border-radius: 2px;
    height: 36px;
    width: 100%;
    margin-bottom: 4px;
  }
</style>

<input on:click={handleClick} type="text" {value} />
