<script>
  import You from './you.svelte'
  import Tokens from './tokens.svelte'
  import Players from './players.svelte'
  import Instructions from './instructions.svelte'

  export let you
  export let others

  const options = ['You', 'Tokens', 'Players', 'Instructions']
  let current = 'You'
  const select = next => () => current = next
</script>

<style>
  div {
    background: #FFE8E8;
    height: 100vh;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
  }

  li {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;
    background: #FFF2F2;
    border: 1px solid #FFE8E8;
    width: 100px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li.active {
    background: #FFE8E8;
    font-weight: bold;
  }
</style>

<div>
  <ul>
  {#each options as option}
    <li class:active={option === current} on:click={select(option)}>{option}</li>
  {/each}
  </ul>
  {#if current === 'You'}
  <You {you} />
  {:else if current === 'Tokens'}
  <Tokens {you} />
  {:else if current === 'Players'}
  <Players {you} {others} />
  {:else if current === 'Instructions'}
  <Instructions />
  {/if}
</div>
