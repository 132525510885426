<script>
  import Value from './components/value.svelte'

  export let you
</script>

<style>
  .container {
    padding: 20px 30px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>

<div class='container'>
  <div class='flex'>
    <Value label='Home Base:' value='({you.base.x}, {you.base.y})' />
    <Value label='Bot Capacity:' value={you.botCapacity} />
    <Value label='Acid Boots:' value='false' />
  </div>
</div>
