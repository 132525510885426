<script>
  export let others
  export let you
</script>

<style>
  ul {
    margin: 0;
    padding: 23px 28px;
    list-style: none;
  }

  li {
    color: white;
    font-size: 18px;
    margin-bottom: 7px;
    padding: 16px 22px;
  }
</style>

<ul>
  <li style='background:{you.color};'>{you.name} (you)</li>
  {#each others as player (player.id)}
  <li style='background:{player.color};'>{player.name}</li>
  {/each}
</ul>
