<script>
  import Game from './game.svelte'

  import KeithMD from './keith.md'

  const ws = new WebSocket('wss://robosplore-api.herokuapp.com/')
  // const ws = new WebSocket('ws://localhost:8080/')

  let mode
  let canJoin = false
  ws.onopen = () => (canJoin = true)
</script>

<style>
</style>

{#if mode === 'Keith'}
  <div>{@html KeithMD}</div>
{:else if mode === 'Game'}
  <Game ws={ws} />
{:else}
  <button disabled={!canJoin} on:click={() => mode = 'Game'}>Normal Mode</button>
  <button on:click={() => mode = 'Keith'}>Keith Mode</button>
{/if}

