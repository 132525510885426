<script>
  export let gameMap
  export let bots
  export let bases

  const SIZE = 20

  const colors = {
    iron: '#a5a5a5',
    water: '#3eafff',
    grass: '#bfffc2',
    acid: '#9a209a',
    copper: '#ffb939'
  }
  const styleString = (x, y, color) =>
    `top: ${y * SIZE + 50}px; left: ${x * SIZE + 200}px; background: ${color}`
  const stripes = color =>
    `repeating-linear-gradient(135deg, transparent, transparent 3px, ${color} 3px, ${color} 6px`
</script>

<style>
  .wrapper {
    flex: 1;
  }

  .tile {
    width: var(--tile-size);
    height: var(--tile-size);
    position: absolute;
  }

  .bot {
    transition: all 500ms;
    border-radius: var(--tile-size);
    border: 2px solid black;
    box-sizing: border-box;
  }
</style>

<div class="wrapper">
  {#each gameMap as col, i}
    {#each col as cell, j}
      <div class="tile" style={styleString(i, j, colors[cell])} />
    {/each}
  {/each}

  {#each bases as base (base.id)}
    <div class="tile" style={styleString(base.x, base.y, stripes(base.color))} />
  {/each}

  {#each bots as bot (bot.id)}
    <div class="tile bot" style={styleString(bot.x, bot.y, bot.color)} />
  {/each}
</div>
